import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer } from '@elastic/eui';
import PrimaryBoxInfo from 'components/Advanced_User/All_OpenHAB_Tutorials/PrimaryBox';
import PrimaryBox from 'components/Advanced_User/OpenHAB_IP_Camera_Binding/PrimaryBox';
import TimeLine from 'components/Products/CommentList/MqttIFTTTList';
import NavButtons from 'components/Advanced_User/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "OpenHAB 2 IP Camera Binding",
  "path": "/Advanced_User/OpenHAB_IP_Camera_Binding/",
  "dateChanged": "2018-09-28",
  "author": "Mike Polinowski",
  "excerpt": "The open Home Automation Bus (openHAB) is an open source, technology agnostic home automation platform which runs as the center of your smart home. openHAB software integrates different home automation systems, devices and technologies into a single solution. It provides uniform user interfaces, and a common approach to automation rules across the entire system, regardless of the number of manufacturers and sub-systems involved. Control your INSTAR IP camera with your home automation system. Compatible with the camera models IN-6001 HD, IN-6012 HD, IN-6014 HD, IN-5905 HD, IN-5907 HD, IN-7011 HD, IN-8003 Full HD, IN-8015 Full HD, IN-9008 Full HD, IN-9020 Full HD",
  "image": "./AU_SearchThumb_OpenHAB.png",
  "social": "/images/Search/AU_SearchThumb_OpenHAB.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_Advanced_OpenHAB2_white.webp",
  "chapter": "Advanced User",
  "category": "smarthome",
  "type": "OpenHAB"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <SEOHelmet title='OpenHAB 2 IP Camera Binding' dateChanged='2018-09-28' author='Mike Polinowski' tag='INSTAR IP Camera' description='The open Home Automation Bus (openHAB) is an open source, technology agnostic home automation platform which runs as the center of your smart home. openHAB software integrates different home automation systems, devices and technologies into a single solution. It provides uniform user interfaces, and a common approach to automation rules across the entire system, regardless of the number of manufacturers and sub-systems involved. Control your INSTAR IP camera with your home automation system. Compatible with the camera models IN-6001 HD, IN-6012 HD, IN-6014 HD, IN-5905 HD, IN-5907 HD, IN-7011 HD, IN-8003 Full HD, IN-8015 Full HD, IN-9008 Full HD, IN-9020 Full HD' image='/images/Search/AU_SearchThumb_OpenHAB.png' twitter='/images/Search/AU_SearchThumb_OpenHAB.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Erweitert/OpenHAB_IP_Camera_Binding/' locationFR='/fr/Advanced_User/OpenHAB_IP_Camera_Binding/' crumbLabel="OpenHAB" mdxType="BreadCrumbs" />
 
    <NavButtons mdxType="NavButtons" />
    <h1 {...{
      "id": "openhab-2-ip-camera-binding",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#openhab-2-ip-camera-binding",
        "aria-label": "openhab 2 ip camera binding permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`OpenHAB 2 IP Camera Binding`}</h1>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#installation"
        }}>{`Installation`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#configuration"
        }}>{`Configuration`}</a></li>
    </ul>
    <EuiSpacer mdxType="EuiSpacer" />
    <PrimaryBox mdxType="PrimaryBox" />
    <EuiSpacer mdxType="EuiSpacer" />
    <TimeLine mdxType="TimeLine" />
    <EuiSpacer mdxType="EuiSpacer" />
    <h2 {...{
      "id": "installation",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#installation",
        "aria-label": "installation permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Installation`}</h2>
    <ol>
      <li parentName="ol">{`Download the binding already built in JAR format `}<a parentName="li" {...{
          "href": "http://www.pcmus.com/openhab/IpCameraBinding/"
        }}>{`from here`}</a>{` (currently this is the version `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`ipcamera-2020-02-01.zip`}</code>{`):`}</li>
    </ol>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "840px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/6026f6057e4ee4f6309c7a7500961155/1e088/OpenHAB_IP_Camera_Binding_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "27.82608695652174%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAIAAABM9SnKAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABHElEQVQY022MTUsCYRRG54dVZhFaaanTFxEIQcugVdiyX9WiH6BIoiM1IUwGZZhKjqPOzH3vx9zQVkKHs3g4i8cqnZwV7ONC0T4o2td7+ftDu5LN3e3mbzP7lcz+zU52M729ltpaX7qRSucKpxfnZbt0VL68slqtZrf77rqu0243G43mU+PZaXXc1xfHqVdr9WrN896IKI5jREAEADAAxiy0ggBVE2ZGY5AImUnYECITEgEiEalqkqgxCqB/m0gNqBWFQiREyTJqsoqqisjiGnkS0CRARDIo4ZyDKVmdTuh54vsiohKDiuh/MFPvq9f7/BgMBiIswqpq9fvQ/8bhEH/GFD480mjEqkzEqyCi70/GY386m81n8yiKROQXLcYw4xvm2GQAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/6026f6057e4ee4f6309c7a7500961155/e4706/OpenHAB_IP_Camera_Binding_01.avif 230w", "/en/static/6026f6057e4ee4f6309c7a7500961155/d1af7/OpenHAB_IP_Camera_Binding_01.avif 460w", "/en/static/6026f6057e4ee4f6309c7a7500961155/32fee/OpenHAB_IP_Camera_Binding_01.avif 840w"],
              "sizes": "(max-width: 840px) 100vw, 840px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/6026f6057e4ee4f6309c7a7500961155/a0b58/OpenHAB_IP_Camera_Binding_01.webp 230w", "/en/static/6026f6057e4ee4f6309c7a7500961155/bc10c/OpenHAB_IP_Camera_Binding_01.webp 460w", "/en/static/6026f6057e4ee4f6309c7a7500961155/ed60d/OpenHAB_IP_Camera_Binding_01.webp 840w"],
              "sizes": "(max-width: 840px) 100vw, 840px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/6026f6057e4ee4f6309c7a7500961155/81c8e/OpenHAB_IP_Camera_Binding_01.png 230w", "/en/static/6026f6057e4ee4f6309c7a7500961155/08a84/OpenHAB_IP_Camera_Binding_01.png 460w", "/en/static/6026f6057e4ee4f6309c7a7500961155/1e088/OpenHAB_IP_Camera_Binding_01.png 840w"],
              "sizes": "(max-width: 840px) 100vw, 840px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/6026f6057e4ee4f6309c7a7500961155/1e088/OpenHAB_IP_Camera_Binding_01.png",
              "alt": "OpenHAB IP Camera Binding für INSTAR Kameras",
              "title": "OpenHAB IP Camera Binding für INSTAR Kameras",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">{`Place the JAR file after you unzip it into your openhab2-addons folder `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`/usr/share/openhab2/addons`}</code>{`. You don’t need to install the binding from PaperUI - once the JAR file is in the correct folder the binding should be available to use (make sure that you `}<strong parentName="li">{`download the latest version and not`}</strong>{` `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`ipcamera-2020-02-01.zip`}</code>{` used in the commands below):`}</li>
    </ol>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`cd /usr/share/openhab2/addons
sudo wget http://www.pcmus.com/openhab/IpCameraBinding/ipcamera-2020-02-01.zip
sudo unzip ipcamera-2020-02-01.zip
sudo reboot`}</code></pre></div>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "846px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/293b5753d8da062fa3fb98456abcfe50/5b481/OpenHAB_IP_Camera_Binding_02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "46.52173913043478%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAIAAAC9o5sfAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABuElEQVQoz23O7W6aUBzHce5EsY11s1OJVkexmXUSqisP4hNSqKctL7BqzbS9p73ZXKsNm4AIjCfdRS3MLNuyJZ/8c9588zvQi+HHw4cPB1eLvZofJfz96iZS+44xX7vgsQUWnPTMS88tsKiLT3Vx3uzNaeGp2p6dtWdEcwYRI5+Zbmt3AT508YFLTfzKwDntu5WBhw+9Y8lJ8es8sDO8+bJjHHLrRMuIMfoOVJKdct8py+7ZyCOGXm0clGQ3zRkHrBavh/ZpNc7qMKVGzpdRUoUpNUZrO9Br8K147aBXNnptFYBZ6JkFYCKimRYsRLDSFyFEsBDRyl7aCG9m+HWisXrVMeKsDqVulKS0yAAt1XZTHTfZcOKshzUVmvv0jvt83n3cXbo7K7Xm4W9/zYbL2K2BT9bloV27276VgzeSX7wJ8pyOtr9gvFq8UNHO8kTQsg0lQSrwH2UY50SLnW4rfY+c+NWxhw9cYuTlLq0IpUdpPfLT7gHTf82G8VHPYu+3+K3HTDfke78sO+QkOJHsPUYL0b/F/gElgXI8XualFdoLMBDkhQDtbY4EOyuaOdFKNFcw9Z9sF/8AxEnqJEadch4AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/293b5753d8da062fa3fb98456abcfe50/e4706/OpenHAB_IP_Camera_Binding_02.avif 230w", "/en/static/293b5753d8da062fa3fb98456abcfe50/d1af7/OpenHAB_IP_Camera_Binding_02.avif 460w", "/en/static/293b5753d8da062fa3fb98456abcfe50/36518/OpenHAB_IP_Camera_Binding_02.avif 846w"],
              "sizes": "(max-width: 846px) 100vw, 846px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/293b5753d8da062fa3fb98456abcfe50/a0b58/OpenHAB_IP_Camera_Binding_02.webp 230w", "/en/static/293b5753d8da062fa3fb98456abcfe50/bc10c/OpenHAB_IP_Camera_Binding_02.webp 460w", "/en/static/293b5753d8da062fa3fb98456abcfe50/47c32/OpenHAB_IP_Camera_Binding_02.webp 846w"],
              "sizes": "(max-width: 846px) 100vw, 846px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/293b5753d8da062fa3fb98456abcfe50/81c8e/OpenHAB_IP_Camera_Binding_02.png 230w", "/en/static/293b5753d8da062fa3fb98456abcfe50/08a84/OpenHAB_IP_Camera_Binding_02.png 460w", "/en/static/293b5753d8da062fa3fb98456abcfe50/5b481/OpenHAB_IP_Camera_Binding_02.png 846w"],
              "sizes": "(max-width: 846px) 100vw, 846px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/293b5753d8da062fa3fb98456abcfe50/5b481/OpenHAB_IP_Camera_Binding_02.png",
              "alt": "OpenHAB IP Camera Binding für INSTAR Kameras",
              "title": "OpenHAB IP Camera Binding für INSTAR Kameras",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol">{`Install FFMPEG`}</li>
    </ol>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`sudo apt update
sudo apt install ffmpeg`}</code></pre></div>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "845px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/3f6ed452b6185989b95538561cd85ca0/8bd7c/OpenHAB_IP_Camera_Binding_03.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "12.608695652173912%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAADCAIAAAAcOLh5AAAACXBIWXMAAA7DAAAOwwHHb6hkAAAAmklEQVQI12MwKNhsFb03NnxFfOyq0JAVwcHLo8KWq/vukPfbq+S/Bz9iCCg65Vd0wjPnqHXCQbPYA1rBu9UD92iG7lcP3qvot1vBd7ei3x4F391K/nvgXBCCaA4sORVQciq08kxQ6emQijN+xaeCyk77F58KLD3tlHnMt/CkS9Yxj9zjLlnHnDOPeRWccMk65pJ13DPvuF3qEQBbx0/M7HcS4QAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/3f6ed452b6185989b95538561cd85ca0/e4706/OpenHAB_IP_Camera_Binding_03.avif 230w", "/en/static/3f6ed452b6185989b95538561cd85ca0/d1af7/OpenHAB_IP_Camera_Binding_03.avif 460w", "/en/static/3f6ed452b6185989b95538561cd85ca0/e50f5/OpenHAB_IP_Camera_Binding_03.avif 845w"],
              "sizes": "(max-width: 845px) 100vw, 845px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/3f6ed452b6185989b95538561cd85ca0/a0b58/OpenHAB_IP_Camera_Binding_03.webp 230w", "/en/static/3f6ed452b6185989b95538561cd85ca0/bc10c/OpenHAB_IP_Camera_Binding_03.webp 460w", "/en/static/3f6ed452b6185989b95538561cd85ca0/c4f2e/OpenHAB_IP_Camera_Binding_03.webp 845w"],
              "sizes": "(max-width: 845px) 100vw, 845px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/3f6ed452b6185989b95538561cd85ca0/81c8e/OpenHAB_IP_Camera_Binding_03.png 230w", "/en/static/3f6ed452b6185989b95538561cd85ca0/08a84/OpenHAB_IP_Camera_Binding_03.png 460w", "/en/static/3f6ed452b6185989b95538561cd85ca0/8bd7c/OpenHAB_IP_Camera_Binding_03.png 845w"],
              "sizes": "(max-width: 845px) 100vw, 845px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/3f6ed452b6185989b95538561cd85ca0/8bd7c/OpenHAB_IP_Camera_Binding_03.png",
              "alt": "OpenHAB IP Camera Binding für INSTAR Kameras",
              "title": "OpenHAB IP Camera Binding für INSTAR Kameras",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h2 {...{
      "id": "configuration",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#configuration",
        "aria-label": "configuration permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Configuration`}</h2>
    <ol>
      <li parentName="ol">{`Open the OpenHAB Paper UI and navigate to your Inbox and click on the `}<strong parentName="li">{`Plus`}</strong>{` button:`}</li>
    </ol>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "842px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/4b6823d3d94f0cd6f62df9e0c43aebc4/99072/OpenHAB_IP_Camera_Binding_04.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "42.608695652173914%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAYAAAAywQxIAAAACXBIWXMAAA7CAAAOwgEVKEqAAAABCUlEQVQoz52PzUrDQBRGrz87u+3evc/gUovgMwiKVgoK0udohbrwNXwLN76AG7vrskkhzUySztwj0zTBhCLWDw4zzJzLfCM67MLgAO0L2t9D7/d3Z9hFH47Qxw6y6h9iBh24FbgTuPoHYfZa4EaQt8mYp9cP0tNjkssTzMsFZnSOGff+jB2dkT331shXDO8z8J9TVtMZDnamAIyD1IHgss1RGfUOvK9XtEl57hqeKwoyk2LTFFEFr6AuSB5VXRNiM0sURyyXS+IowlpbPrpxanfjh0j78ifee4qiwDlXr7/5AaGVup21GGPI85w4jlksFsznc5IkaXjtbG1YDYSGgdCs2ge2fbtq+A1crHB2OKq6lgAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/4b6823d3d94f0cd6f62df9e0c43aebc4/e4706/OpenHAB_IP_Camera_Binding_04.avif 230w", "/en/static/4b6823d3d94f0cd6f62df9e0c43aebc4/d1af7/OpenHAB_IP_Camera_Binding_04.avif 460w", "/en/static/4b6823d3d94f0cd6f62df9e0c43aebc4/899b7/OpenHAB_IP_Camera_Binding_04.avif 842w"],
              "sizes": "(max-width: 842px) 100vw, 842px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/4b6823d3d94f0cd6f62df9e0c43aebc4/a0b58/OpenHAB_IP_Camera_Binding_04.webp 230w", "/en/static/4b6823d3d94f0cd6f62df9e0c43aebc4/bc10c/OpenHAB_IP_Camera_Binding_04.webp 460w", "/en/static/4b6823d3d94f0cd6f62df9e0c43aebc4/6366e/OpenHAB_IP_Camera_Binding_04.webp 842w"],
              "sizes": "(max-width: 842px) 100vw, 842px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/4b6823d3d94f0cd6f62df9e0c43aebc4/81c8e/OpenHAB_IP_Camera_Binding_04.png 230w", "/en/static/4b6823d3d94f0cd6f62df9e0c43aebc4/08a84/OpenHAB_IP_Camera_Binding_04.png 460w", "/en/static/4b6823d3d94f0cd6f62df9e0c43aebc4/99072/OpenHAB_IP_Camera_Binding_04.png 842w"],
              "sizes": "(max-width: 842px) 100vw, 842px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/4b6823d3d94f0cd6f62df9e0c43aebc4/99072/OpenHAB_IP_Camera_Binding_04.png",
              "alt": "OpenHAB IP Camera Binding für INSTAR Kameras",
              "title": "OpenHAB IP Camera Binding für INSTAR Kameras",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">{`Select the `}<strong parentName="li">{`IPCameraBinding`}</strong>{`:`}</li>
    </ol>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "843px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/9de5abf734b1da56d5953e991470687d/4ff83/OpenHAB_IP_Camera_Binding_05.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "37.391304347826086%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAIAAACHqfpvAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAA1UlEQVQY05XLsU7DMBRGYQs6woRUVbwcKgtdukCeAwbE3FegDLD3MZq4DVLC4Kqu1OReE9vXP6oYGxAcfetRuL1I2Uim53I3lGwk2WWanuHmBJPTXw0wGShcKYwVrv9pfBgVL2b0+kjzB3rpM+/zfE9vT7yYKcTYLEtTbba12VRmWxvffMbWh7YT8oljD5dCZRCCQui6fMlak855pUkXrS54pdsi797L9FFLXR2L5RqhUwCIyFrb7Pc7a3fWEhEApPQjkSQC4DAHH5idc46Jvff4Q9/zF4OfS44kxELGAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/9de5abf734b1da56d5953e991470687d/e4706/OpenHAB_IP_Camera_Binding_05.avif 230w", "/en/static/9de5abf734b1da56d5953e991470687d/d1af7/OpenHAB_IP_Camera_Binding_05.avif 460w", "/en/static/9de5abf734b1da56d5953e991470687d/c217d/OpenHAB_IP_Camera_Binding_05.avif 843w"],
              "sizes": "(max-width: 843px) 100vw, 843px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/9de5abf734b1da56d5953e991470687d/a0b58/OpenHAB_IP_Camera_Binding_05.webp 230w", "/en/static/9de5abf734b1da56d5953e991470687d/bc10c/OpenHAB_IP_Camera_Binding_05.webp 460w", "/en/static/9de5abf734b1da56d5953e991470687d/e3019/OpenHAB_IP_Camera_Binding_05.webp 843w"],
              "sizes": "(max-width: 843px) 100vw, 843px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/9de5abf734b1da56d5953e991470687d/81c8e/OpenHAB_IP_Camera_Binding_05.png 230w", "/en/static/9de5abf734b1da56d5953e991470687d/08a84/OpenHAB_IP_Camera_Binding_05.png 460w", "/en/static/9de5abf734b1da56d5953e991470687d/4ff83/OpenHAB_IP_Camera_Binding_05.png 843w"],
              "sizes": "(max-width: 843px) 100vw, 843px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/9de5abf734b1da56d5953e991470687d/4ff83/OpenHAB_IP_Camera_Binding_05.png",
              "alt": "OpenHAB IP Camera Binding für INSTAR Kameras",
              "title": "OpenHAB IP Camera Binding für INSTAR Kameras",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ol {...{
      "start": 4
    }}>
      <li parentName="ol">{`Wait for the discovery and select your camera:`}</li>
    </ol>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "831px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/ff77025dae09a48a4972d1373dbfe3e2/5b4a1/OpenHAB_IP_Camera_Binding_06a.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "42.173913043478265%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAIAAAB2/0i6AAAACXBIWXMAAA7DAAAOwwHHb6hkAAABCklEQVQY04WPzUrEMBSFg+Aj6VY67yH+IfgmVguC6AvoyoXPoA/QrTtd2c502k6aNEmnSZPcK/1ZqDPox7lwNoePS/CIwMUunO/gKelzRvCE4PFQDv8JwXD/5u756fYewz0TzWwU2CjookBfHejrwPyRaEYQfZzYt4X21vC6ZowzzoWUHtEDOOed/5EBGDtBxMb4tbaNUrUQUinVKCFk27bGdLiBG/DeI2I/vnyxD7FtWLEsiizLKkpXq7IoClpRrfX3JQAwxjjnUspp/F66lDlr2nU7oKczxgDAphwGxjGEr/Yx7liefiZpmqRZtuiZp8s8/232nlJaMSaEmMwfpZvz/gfYJtmqHc1fYmSZosnb5jcAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/ff77025dae09a48a4972d1373dbfe3e2/e4706/OpenHAB_IP_Camera_Binding_06a.avif 230w", "/en/static/ff77025dae09a48a4972d1373dbfe3e2/d1af7/OpenHAB_IP_Camera_Binding_06a.avif 460w", "/en/static/ff77025dae09a48a4972d1373dbfe3e2/ea25a/OpenHAB_IP_Camera_Binding_06a.avif 831w"],
              "sizes": "(max-width: 831px) 100vw, 831px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/ff77025dae09a48a4972d1373dbfe3e2/a0b58/OpenHAB_IP_Camera_Binding_06a.webp 230w", "/en/static/ff77025dae09a48a4972d1373dbfe3e2/bc10c/OpenHAB_IP_Camera_Binding_06a.webp 460w", "/en/static/ff77025dae09a48a4972d1373dbfe3e2/6d405/OpenHAB_IP_Camera_Binding_06a.webp 831w"],
              "sizes": "(max-width: 831px) 100vw, 831px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/ff77025dae09a48a4972d1373dbfe3e2/81c8e/OpenHAB_IP_Camera_Binding_06a.png 230w", "/en/static/ff77025dae09a48a4972d1373dbfe3e2/08a84/OpenHAB_IP_Camera_Binding_06a.png 460w", "/en/static/ff77025dae09a48a4972d1373dbfe3e2/5b4a1/OpenHAB_IP_Camera_Binding_06a.png 831w"],
              "sizes": "(max-width: 831px) 100vw, 831px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/ff77025dae09a48a4972d1373dbfe3e2/5b4a1/OpenHAB_IP_Camera_Binding_06a.png",
              "alt": "OpenHAB IP Camera Binding für INSTAR Kameras",
              "title": "OpenHAB IP Camera Binding für INSTAR Kameras",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`If the discovery cannot find your camera, click in `}<strong parentName="p">{`Add Manually`}</strong>{` aand select `}<em parentName="p">{`INSTAR HD IP camera with ONVIF and API support`}</em></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "844px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/34148b1fe1bbc561bec8b43c56f92680/33e10/OpenHAB_IP_Camera_Binding_06b.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "99.13043478260869%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAIAAAAC64paAAAACXBIWXMAAA7DAAAOwwHHb6hkAAACZ0lEQVQ4y3WT23IUIRCG58LyWr2wEqt8NBMvTEqvTGni42ieK+4p2SSl2eHUQNPAABawrrFcqb/gH2aar2mYIX0+yOcH6ex5Oj9IF4fpy+v06WU6e5EuXqXzw/zxWXn/pHx4uldDOh7KUdNx09vW7x6PhvLmvxrs1xP37R1dntJlNc2fdE/18cRdnrr69rT6vzWkUmgqzCXhkp0KxAIhQygqZElFxyJ9hphVyDGXXLZKrR/SFD05DcpoQGOsBtN8m9HWaqPBGh085TSVnLrSVP2QcybySiljrTFGa23rElopMNZq0MYYAAgxlEct51xKqcHe12BECwBSqrqQ1gBg+gBag+5f71pKqQanlLwPSikhBCJai8YYa63RNQtEV3NBq5QMIewhkyelFCilOwmaUdDgpqGN99Rpe8h9eVv3bRsYAaAOrRCISET790xEQoiGBCmkkkpJJaXcZgHAOUe0+/ccY+wQdI5a8947RGus975POqKpHk/+h+wc51xKoWq15ThupJSSC9Gy4EJIKRVADd5LxtaICH/jbUsGEZ1z6FwIwaGLMe423cmJyNe1lQTQDVKPGjRgDbY1uJ4hGm16cM552pJDpOVKX323s7m+muF8gfOFnVUTrm+qVtfdTOvbfHefbu/SzTrd3bdgre1szgRXjPGRCTayzUaMjG0eeDUje9jUGcY9ujJNXeH6poQwpBj9em1nc7dY7kTLFS1XuFjifEGrlVss/2A7+cfPbbUd0ciYUGrDRqmASzmykUvFBGeCMSFGLqZH16tWuxcspfp/xRBiCMH7bqpiiLWrk9uLmfNOuRXsF/MJNHCC3Y/lAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/34148b1fe1bbc561bec8b43c56f92680/e4706/OpenHAB_IP_Camera_Binding_06b.avif 230w", "/en/static/34148b1fe1bbc561bec8b43c56f92680/d1af7/OpenHAB_IP_Camera_Binding_06b.avif 460w", "/en/static/34148b1fe1bbc561bec8b43c56f92680/dfb04/OpenHAB_IP_Camera_Binding_06b.avif 844w"],
              "sizes": "(max-width: 844px) 100vw, 844px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/34148b1fe1bbc561bec8b43c56f92680/a0b58/OpenHAB_IP_Camera_Binding_06b.webp 230w", "/en/static/34148b1fe1bbc561bec8b43c56f92680/bc10c/OpenHAB_IP_Camera_Binding_06b.webp 460w", "/en/static/34148b1fe1bbc561bec8b43c56f92680/20d15/OpenHAB_IP_Camera_Binding_06b.webp 844w"],
              "sizes": "(max-width: 844px) 100vw, 844px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/34148b1fe1bbc561bec8b43c56f92680/81c8e/OpenHAB_IP_Camera_Binding_06b.png 230w", "/en/static/34148b1fe1bbc561bec8b43c56f92680/08a84/OpenHAB_IP_Camera_Binding_06b.png 460w", "/en/static/34148b1fe1bbc561bec8b43c56f92680/33e10/OpenHAB_IP_Camera_Binding_06b.png 844w"],
              "sizes": "(max-width: 844px) 100vw, 844px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/34148b1fe1bbc561bec8b43c56f92680/33e10/OpenHAB_IP_Camera_Binding_06b.png",
              "alt": "OpenHAB IP Camera Binding für INSTAR Kameras",
              "title": "OpenHAB IP Camera Binding für INSTAR Kameras",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ol {...{
      "start": 5
    }}>
      <li parentName="ol">{`To use the new streaming features, you need to:`}</li>
    </ol>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "821px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/4921993fb5d33012bee7f2397efb313b/02cd5/OpenHAB_IP_Camera_Binding_07.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "58.26086956521739%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAACXBIWXMAAA7EAAAOxAGVKw4bAAAB9klEQVQoz32Ra08TQRSGNxpjjN+MgvrDEJGLfjAaISZS/SkE0Kp/wH9hTLx8gVAaewOk7dYUWrrbZWdm5/KY3S6lCeBJnpw5mXfOzHvG480dKNzFrE5h3z3EFqaxq/dG+e2DnPujujCNe3UT9+Ia7uX1c9J65TZu+RaefH6DYGUKljx4lvM0Z7JeypnPeZLnhTyne4se3pfiZ14Xy5yuzxCtzxKtPSJamyHeeEy8PpshPswjigvIlI+LyOIi4tMS8ft54o054s25kX5zDu+g+pev3+qI9hE6Ac0IM4HqhYStLmH7iKDdJfCPCA98EmnHmrMznt7eQvwuEX3/ge50cIDVyRhwqJ0ScblMUq8jKxVkrYb4+Qvt+1jcSGcNOIOnhhFBq40YDJBCoJRCSpmRrtMwUjL0Owx9H9nvo6IIGQ4zvRCCRCmccxmeqDcIGg1Ms8VVobtdhpUqUauN2tsHay9o0mZpeKfVGr1KFbG3j0yS7MaU7JVCZCNQbZ/jrW2iwybD3XLmRqROcp3W+vyFMgw5buzR73Q46fUYDAYEQZDnECEd1phRk/4Jp4OAWAjiOM5Im1lrJyxvbROWykS7ZZKd0gUrl7i7NMaWxWEzm2Ga4z+HyPxTzj5HGz0+cBWT+552jlipbCYiSTBaY4wZM2nnf9jcyj9uAlRhrEnvcAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/4921993fb5d33012bee7f2397efb313b/e4706/OpenHAB_IP_Camera_Binding_07.avif 230w", "/en/static/4921993fb5d33012bee7f2397efb313b/d1af7/OpenHAB_IP_Camera_Binding_07.avif 460w", "/en/static/4921993fb5d33012bee7f2397efb313b/d02b5/OpenHAB_IP_Camera_Binding_07.avif 821w"],
              "sizes": "(max-width: 821px) 100vw, 821px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/4921993fb5d33012bee7f2397efb313b/a0b58/OpenHAB_IP_Camera_Binding_07.webp 230w", "/en/static/4921993fb5d33012bee7f2397efb313b/bc10c/OpenHAB_IP_Camera_Binding_07.webp 460w", "/en/static/4921993fb5d33012bee7f2397efb313b/d77d0/OpenHAB_IP_Camera_Binding_07.webp 821w"],
              "sizes": "(max-width: 821px) 100vw, 821px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/4921993fb5d33012bee7f2397efb313b/81c8e/OpenHAB_IP_Camera_Binding_07.png 230w", "/en/static/4921993fb5d33012bee7f2397efb313b/08a84/OpenHAB_IP_Camera_Binding_07.png 460w", "/en/static/4921993fb5d33012bee7f2397efb313b/02cd5/OpenHAB_IP_Camera_Binding_07.png 821w"],
              "sizes": "(max-width: 821px) 100vw, 821px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/4921993fb5d33012bee7f2397efb313b/02cd5/OpenHAB_IP_Camera_Binding_07.png",
              "alt": "OpenHAB IP Camera Binding für INSTAR Kameras",
              "title": "OpenHAB IP Camera Binding für INSTAR Kameras",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ul>
      <li parentName="ul">{`Set a valid SERVER_PORT as the default value of -1 will turn the features off.`}</li>
      <li parentName="ul">{`Add any IPs that need access to the IP_WHITELIST surrounding each one in brackets (see below example). Internal IPs will trigger a warning in the logs if they are not in the whitelist, however external IPs or localhost will not trigger a warning in the logs as they are completely ignored and the binding will refuse to connect to them. This is a security feature.`}</li>
      <li parentName="ul">{`Ensure ffmpeg is installed.`}</li>
      <li parentName="ul">{`For cameras that do not auto detect the H264 stream which is done for ONVIF cameras, you will need to use the FFMPEG_INPUT and provide a http or rtsp link. This is used for both the HLS and animated GIF features.
For most brands the ONVIF_MEDIA_PROFILE needs to match the stream number you have setup for h264. This is usually 0 and is the main-stream, the higher numbers are the sub-streams if your camera has any. The DEBUG log output will help guide you with this in the openhab.log if ONVIF is setup correctly.`}</li>
    </ul>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "845px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/3bb1b2f2e9ae0acde893b4949c297448/8bd7c/OpenHAB_IP_Camera_Binding_07a.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "53.47826086956522%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAB0ElEQVQoz22RXW/TMBSGIyEQX524427/iH/BBagr3KH9LP4EEjeTYGwdYus6RMNI2yRNEzuOvx5kp9kmmKVHss97/Pqc40TUNfL3FatsSXs9g8N9mDyFyUP820f4yQg/foIfP8a/f4F/N4oxXif/8+YByXa7pZMC48HkC/zhPsX4JZ8+vMIejLCTPczBqGeyF88xNn52gx32B89J6rpGa411Dt0p3PV3sqsZH49KltPPVBdHqMUU9euUbnFKffmF6vyI5vJr3A8EXadnJLPZjPl8TpqmZMsljn5p1VC1HZW8RQPCeKQl7rsdhtuVWGtp2zYevPfgHVYp6tUaVRZY3WF2eGcReU6dZaiy7ClytJQ4Z6MeW16v19G0lRIP2DynOD5h8+0EI2QcRwBjEOcXbH6cIy7niKufiOkZJi/ivVBQUhRFNKyqKhKCTmvyRcrmTxYfUUphTN9YkxcUaco2W1KvVsg8j/k3hqHCIXnXN8572q7DOBe1QPi4cKFVCtG2UbNhPLv8oAXTpGmaWIG/E3TOsdmUlGVJ0IUQNI2g63rjUHUkjKltbx6LFd75oP5TdoZSykhIHhj0+9agJYPzv4ahskAYSU+D1u62k3sIhn8BRTMmo8d39rgAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/3bb1b2f2e9ae0acde893b4949c297448/e4706/OpenHAB_IP_Camera_Binding_07a.avif 230w", "/en/static/3bb1b2f2e9ae0acde893b4949c297448/d1af7/OpenHAB_IP_Camera_Binding_07a.avif 460w", "/en/static/3bb1b2f2e9ae0acde893b4949c297448/e50f5/OpenHAB_IP_Camera_Binding_07a.avif 845w"],
              "sizes": "(max-width: 845px) 100vw, 845px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/3bb1b2f2e9ae0acde893b4949c297448/a0b58/OpenHAB_IP_Camera_Binding_07a.webp 230w", "/en/static/3bb1b2f2e9ae0acde893b4949c297448/bc10c/OpenHAB_IP_Camera_Binding_07a.webp 460w", "/en/static/3bb1b2f2e9ae0acde893b4949c297448/c4f2e/OpenHAB_IP_Camera_Binding_07a.webp 845w"],
              "sizes": "(max-width: 845px) 100vw, 845px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/3bb1b2f2e9ae0acde893b4949c297448/81c8e/OpenHAB_IP_Camera_Binding_07a.png 230w", "/en/static/3bb1b2f2e9ae0acde893b4949c297448/08a84/OpenHAB_IP_Camera_Binding_07a.png 460w", "/en/static/3bb1b2f2e9ae0acde893b4949c297448/8bd7c/OpenHAB_IP_Camera_Binding_07a.png 845w"],
              "sizes": "(max-width: 845px) 100vw, 845px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/3bb1b2f2e9ae0acde893b4949c297448/8bd7c/OpenHAB_IP_Camera_Binding_07a.png",
              "alt": "OpenHAB IP Camera Binding für INSTAR Kameras",
              "title": "OpenHAB IP Camera Binding für INSTAR Kameras",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`The default ffmpeg directory is `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`/usr/bin/ffmpeg`}</code>{` you can verify this with the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`which ffmpeg`}</code>{` command on your linux bash. For the output directory you can choose a path - in the example I added a folder `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`/usr/share/openhab2/cameratmpfs/8015`}</code>{` inside the OpenHAB 2 install directory. Make sure to set the owner for this directory - recursively - to `}<strong parentName="p">{`openhab`}</strong>{`: `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`sudo chown -R openhab:openhab /usr/share/openhab2/cameratmpfs`}</code>{` to allow OpenHAB to use it.`}</p>
    <ol {...{
      "start": 6
    }}>
      <li parentName="ol">{`Now go to `}<strong parentName="li">{`Configuration/Things`}</strong>{` and select the camera you just created:`}</li>
    </ol>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "842px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/8c4625fb6f2d17aa4adf9a4d787caab8/99072/OpenHAB_IP_Camera_Binding_08.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "59.130434782608695%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAIAAADtbgqsAAAACXBIWXMAAA7DAAAOwwHHb6hkAAACA0lEQVQoz32RTWsTQRzGB1T0a3gQj75gNCdPggdBbHsTL6YeGjWSjd3N7szsvGTTVPwC3rQ38SYWRAJWUBMPTVJjL0lFE7FIxCYb2d1kkk0zspsgQiU/nut/npcB/nDY+d4c/fi6+2XHG46rr56qJwGPHqWRQ7N0/giNHgOO4zhde+A67u+ulLL8/LF2HPCzh+kpMEunAT0DQLu91++L8Vjuj0ZSysrGevLSCbxwAV47h+ZmKILnIsB13J7X831fCCGlLFW20ohauYd85QG1cv+VybOIWZBmwPb2p1qtVq/XG41Gq9XK5/OplKJBVYMqJeZBEWISE5sYYYTAQIiubbuOI/p9KWWxUEjcTcSX40v3lxhjlFJGCDcJJxRjDAMMVVV1XeecAynlwPeH+wFB52pVSSmc8wzPsPAaWRl9JWtkszR861+A12ju1eqdnc9u89u49bP4Yn3+5kIsGTMxwQgZlK7FblWiF19enbe0NAzNtXTaMAzGGOi+L+5uvGm9ffer+MHdLBWerCWVFJ1Ww5DSR/E7ry9feXb9hgWhGRqSkMC5L8QwTDyJXa5+vJdI3F6OL6YWGeOMUmhZ2mpOz+UwIeFMSNd1CGHg3Gm3e15v5PuTfy5vllKKggk2sDEpNh1skiUAQ4QwxsGxbdue5wkhpoNVtlRV5ZyzA/P8hbHJlPQPERacxUj4rG8AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/8c4625fb6f2d17aa4adf9a4d787caab8/e4706/OpenHAB_IP_Camera_Binding_08.avif 230w", "/en/static/8c4625fb6f2d17aa4adf9a4d787caab8/d1af7/OpenHAB_IP_Camera_Binding_08.avif 460w", "/en/static/8c4625fb6f2d17aa4adf9a4d787caab8/899b7/OpenHAB_IP_Camera_Binding_08.avif 842w"],
              "sizes": "(max-width: 842px) 100vw, 842px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/8c4625fb6f2d17aa4adf9a4d787caab8/a0b58/OpenHAB_IP_Camera_Binding_08.webp 230w", "/en/static/8c4625fb6f2d17aa4adf9a4d787caab8/bc10c/OpenHAB_IP_Camera_Binding_08.webp 460w", "/en/static/8c4625fb6f2d17aa4adf9a4d787caab8/6366e/OpenHAB_IP_Camera_Binding_08.webp 842w"],
              "sizes": "(max-width: 842px) 100vw, 842px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/8c4625fb6f2d17aa4adf9a4d787caab8/81c8e/OpenHAB_IP_Camera_Binding_08.png 230w", "/en/static/8c4625fb6f2d17aa4adf9a4d787caab8/08a84/OpenHAB_IP_Camera_Binding_08.png 460w", "/en/static/8c4625fb6f2d17aa4adf9a4d787caab8/99072/OpenHAB_IP_Camera_Binding_08.png 842w"],
              "sizes": "(max-width: 842px) 100vw, 842px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/8c4625fb6f2d17aa4adf9a4d787caab8/99072/OpenHAB_IP_Camera_Binding_08.png",
              "alt": "OpenHAB IP Camera Binding für INSTAR Kameras",
              "title": "OpenHAB IP Camera Binding für INSTAR Kameras",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ol {...{
      "start": 7
    }}>
      <li parentName="ol">{`Click on each `}<strong parentName="li">{`Channel`}</strong>{` that you want to link to an `}<strong parentName="li">{`Item`}</strong>{` on the Paper UI - I choose the `}<strong parentName="li">{`Snapshot from Camera`}</strong>{`:`}</li>
    </ol>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "825px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/9a64c2a75bce2b958b3d35da57cbe588/d4c13/OpenHAB_IP_Camera_Binding_09.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "87.82608695652175%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAASCAIAAADUsmlHAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAC5ElEQVQ4y42SyWsTYRjG56bUBRVU3P4Fwe2splpb7cGbqR48eRDBk0uhoKBmnW++/X1nmtAkBxcQ8eZfUAWrSUWroNal6aJpq0SbOElJm8hMWq3agw+/w8s383zL874GHNvi0bldH9uq2zfpzh26Y7NXeyvbdMcmaFsHbet/c3gdHN0CHZuhfaOBgRZ1ZKNu2wCBVXCwBVrXQGAVtq72itbVGGjBAyv/YP8KPLQWW9dgoMVQwV3HT186d6oLgzt51z4R3C2Ce3wWi669f7OwvscAp7c7AtcIKkCuUKGj7d4mCpdgL4MBAIJZ1CKWZRFChOBKSSmlUgoBPNBjWXlmzrkQUnDBlohSKoSQUgpfSqnlzUopk5mEE9CgfcH/yfD+VfpypLsn1uOgo8FzNk/+JcbYsjsaWgOjFAXnlAOAbduO4/T39+dyuae+stnswMBAIpH49+aGg3A9rs70iFjcjEajsVjMNM1CoTA7O+u6bqlUqtfr1Wo1k8lIKXExuuZGRq8NV6P65EVNKLMsi1LGOc/n8xXXnZmZqbhuo9Eol8vpVEpJaS+6F8yIYAm4EgMhBPEbRikdnZio1etutVqbn683Gj9cN9nXRzmXWgulvNf7ERi9DnSHVOd5FSeUmHGTEE6ssaGX7udCeXyiPD5RLUy6nz4/uH37Xl/f/UzmfiqVQBRS+m+WUnIVobrZUqZUmvFPDx+NvxgaG3xWGHr57d274vBw8e1w8c3b4vsP33ODd9AWoBHAeNXWngiePXIhEYuRSDh8IxQCIb6+flP6OFIeyZc+jvzIj1ZGx+qTU42p6frUdGNq+u7NWxaliGik43GMmldNaM6SlNJ2nMkvXyq1WrFUqtRq1bm5UqWSe/78cTY7kMs9GRxMZzJeYIiGnXR64njigo4TGgmHw+FIKBQSjCGAViqZTKZTqXQq5c09IZaP8qd/oc8hos5dUYwJznnzA6W0GbtlWYwxIQQukR+2l/ZPB7t3SMKOkcUAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/9a64c2a75bce2b958b3d35da57cbe588/e4706/OpenHAB_IP_Camera_Binding_09.avif 230w", "/en/static/9a64c2a75bce2b958b3d35da57cbe588/d1af7/OpenHAB_IP_Camera_Binding_09.avif 460w", "/en/static/9a64c2a75bce2b958b3d35da57cbe588/b018f/OpenHAB_IP_Camera_Binding_09.avif 825w"],
              "sizes": "(max-width: 825px) 100vw, 825px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/9a64c2a75bce2b958b3d35da57cbe588/a0b58/OpenHAB_IP_Camera_Binding_09.webp 230w", "/en/static/9a64c2a75bce2b958b3d35da57cbe588/bc10c/OpenHAB_IP_Camera_Binding_09.webp 460w", "/en/static/9a64c2a75bce2b958b3d35da57cbe588/0244e/OpenHAB_IP_Camera_Binding_09.webp 825w"],
              "sizes": "(max-width: 825px) 100vw, 825px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/9a64c2a75bce2b958b3d35da57cbe588/81c8e/OpenHAB_IP_Camera_Binding_09.png 230w", "/en/static/9a64c2a75bce2b958b3d35da57cbe588/08a84/OpenHAB_IP_Camera_Binding_09.png 460w", "/en/static/9a64c2a75bce2b958b3d35da57cbe588/d4c13/OpenHAB_IP_Camera_Binding_09.png 825w"],
              "sizes": "(max-width: 825px) 100vw, 825px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/9a64c2a75bce2b958b3d35da57cbe588/d4c13/OpenHAB_IP_Camera_Binding_09.png",
              "alt": "OpenHAB IP Camera Binding für INSTAR Kameras",
              "title": "OpenHAB IP Camera Binding für INSTAR Kameras",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ol {...{
      "start": 8
    }}>
      <li parentName="ol">{`We can leave everything by it's default value for now and click on `}<strong parentName="li">{`Link`}</strong>{`:`}</li>
    </ol>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "825px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/c68ba18550f0099a88cd6815d889dddf/d4c13/OpenHAB_IP_Camera_Binding_10.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "110.86956521739131%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAWCAIAAABPIytRAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAChElEQVQ4y4WTzU/UQBjGezMRMOJB/AT/FVYDokD0YvwHvHDQ/8CbiV98dGbesivcSDiaGMDlQIQYgWjQEDRGLpoYd7vdbXfbdKcz206npl33I7ArT540TSe/Pm+fvFVg/PL8+MX05BWYGISxAZgcgtuXtImr8c3EoHbrgjbSr42ea3mkPz4dG4Cb55X54VNPR689GRkiqdMo1Yevn0GpPjTci1K98TV2zxHjG2ch1QupHgVPTT54+Hj740F+f+vXl/c/97Z+73/If9vNfd1JvNvuPwc7he+ffmxn0aP7s1N3FcgszKjEtKwqqzlVz6FMhGHURVLKKIqMkknSryCzoBCM1LnZQkGvcUarVRH4kQxDITo68P1ISj2fw0gFghVCCMbYMAzOOaWUcy6EkF0khIiiqFAoYIwB4B9smibnNcYY57UEjjo6DOVRGCGk6yWr7JZMh3o+46HHREdXqe8H0jDaYFVVHcdp9CGj7qoX1krWgLycg5JlN89OhHVdRwjFMBBCQDOKRUqrnudRShljnufVu2ln2uFW8owKViUeO0gkhAiCIAzDZsl1TEoZJiuQz+cbyUBUDKZV7jaqEIIx1jm5DhdNi/N4Ws/zjmQKISil9YfN5BY8q5JyMnbYAE4srAVjoplW+cSqm3A+l2sVNq2CUbI4Y8d53/ePwxXb1jSNEBInEwL1JWn/2s5K3ld1nEwmQzBWNIAXc6RYtpN/Sfxv6GSx2fOZ8urb9NISQSheTwDQdd11Xdu2ne5yXbcWRe6de/qz6fTyMlHVxnomyb7vB13EObcrFV/Kyt7n4uFhenExHhsAEMLZ9fXNzc133bWxsbG2traezb5eXXmzugIABOAvndxBA2X/8n8AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/c68ba18550f0099a88cd6815d889dddf/e4706/OpenHAB_IP_Camera_Binding_10.avif 230w", "/en/static/c68ba18550f0099a88cd6815d889dddf/d1af7/OpenHAB_IP_Camera_Binding_10.avif 460w", "/en/static/c68ba18550f0099a88cd6815d889dddf/b018f/OpenHAB_IP_Camera_Binding_10.avif 825w"],
              "sizes": "(max-width: 825px) 100vw, 825px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/c68ba18550f0099a88cd6815d889dddf/a0b58/OpenHAB_IP_Camera_Binding_10.webp 230w", "/en/static/c68ba18550f0099a88cd6815d889dddf/bc10c/OpenHAB_IP_Camera_Binding_10.webp 460w", "/en/static/c68ba18550f0099a88cd6815d889dddf/0244e/OpenHAB_IP_Camera_Binding_10.webp 825w"],
              "sizes": "(max-width: 825px) 100vw, 825px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/c68ba18550f0099a88cd6815d889dddf/81c8e/OpenHAB_IP_Camera_Binding_10.png 230w", "/en/static/c68ba18550f0099a88cd6815d889dddf/08a84/OpenHAB_IP_Camera_Binding_10.png 460w", "/en/static/c68ba18550f0099a88cd6815d889dddf/d4c13/OpenHAB_IP_Camera_Binding_10.png 825w"],
              "sizes": "(max-width: 825px) 100vw, 825px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/c68ba18550f0099a88cd6815d889dddf/d4c13/OpenHAB_IP_Camera_Binding_10.png",
              "alt": "OpenHAB IP Camera Binding für INSTAR Kameras",
              "title": "OpenHAB IP Camera Binding für INSTAR Kameras",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ol {...{
      "start": 9
    }}>
      <li parentName="ol">{`Back on the `}<strong parentName="li">{`Control Panel`}</strong>{` you can now see your cameras life video (JPG Stream):`}</li>
    </ol>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "841px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/227ecb57950796118049a6069bbb0ec6/bad1b/OpenHAB_IP_Camera_Binding_11.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "55.65217391304348%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAA7CAAAOwgEVKEqAAAACUUlEQVQoz4WR3UtTcRjHjxaGRS+G5vvMwoK5nbl00Youg4IyK43IqXgv4cVy23k/Z/Omy7rzpv6Cugzvuoss8GXzItELwzQo50TdlM194ixXIkU/+PA8/H7w4fs8PwFgbWmR3Jd5vi3MsZne4fO7N0iigO47hN5RitZe8gu77yg5wN677zDa5TKElYmPLE1N8X16kuXJSZKLX/k09gzlgoDuEdDdAnrbHnYv/oW9e8MjIMy/HWfhwwTLUzOsTE2TnJvn/dgLpJtO1PsdqF1e1O4rKN1+lLte1K521K5LKH8h/MCHkF5fJ7OWgp0d8pltewPMxhNENBPNGkU1Y8i6iaJbqMYokm4h6xaKEf2DGcPQojwfGkbYTG+xlcmQ3s6Q3d0tCBOzs8hSBEPXC1WRJMKREYZHnqCpKpqqYJkGsajFaCyKGTMJBod41duHkEql2NjYIJfLsVsUJhJIklQQ2lVVVIKRIJ2PO+kL9BEIBAj09jLQ38/gwCAPH/VwO3CHsGUhrK7+IJlcI53O/BbG43FCoRCKohSQVAVFlnG53NTW1OJocFBdeYaqitNUHDtJeekR2kUvhqYhZLNZbPYnLAo1WSZoGry+18OMx4fH2UpDo4OWcy00NTZRX11L9akqjpcdxev2oNlC9p39I4fDYVRFIWTovOwfZPzGLVqdrdTVNdDsaKbJcbaQtPJEBeWlZYhON5quIeTzeYocFBZGlmVChsFT08Dvv4rL6aJNbEN0i4iiyMXzLTTW1HPdf+3/CYs7tKX2T0v/IBKJFKosy/wEYgAvywzl3swAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/227ecb57950796118049a6069bbb0ec6/e4706/OpenHAB_IP_Camera_Binding_11.avif 230w", "/en/static/227ecb57950796118049a6069bbb0ec6/d1af7/OpenHAB_IP_Camera_Binding_11.avif 460w", "/en/static/227ecb57950796118049a6069bbb0ec6/f15cc/OpenHAB_IP_Camera_Binding_11.avif 841w"],
              "sizes": "(max-width: 841px) 100vw, 841px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/227ecb57950796118049a6069bbb0ec6/a0b58/OpenHAB_IP_Camera_Binding_11.webp 230w", "/en/static/227ecb57950796118049a6069bbb0ec6/bc10c/OpenHAB_IP_Camera_Binding_11.webp 460w", "/en/static/227ecb57950796118049a6069bbb0ec6/d02c2/OpenHAB_IP_Camera_Binding_11.webp 841w"],
              "sizes": "(max-width: 841px) 100vw, 841px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/227ecb57950796118049a6069bbb0ec6/81c8e/OpenHAB_IP_Camera_Binding_11.png 230w", "/en/static/227ecb57950796118049a6069bbb0ec6/08a84/OpenHAB_IP_Camera_Binding_11.png 460w", "/en/static/227ecb57950796118049a6069bbb0ec6/bad1b/OpenHAB_IP_Camera_Binding_11.png 841w"],
              "sizes": "(max-width: 841px) 100vw, 841px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/227ecb57950796118049a6069bbb0ec6/bad1b/OpenHAB_IP_Camera_Binding_11.png",
              "alt": "OpenHAB IP Camera Binding für INSTAR Kameras",
              "title": "OpenHAB IP Camera Binding für INSTAR Kameras",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ol {...{
      "start": 10
    }}>
      <li parentName="ol">{`Go back to the `}<strong parentName="li">{`Things`}</strong>{` page and link as many `}<strong parentName="li">{`Channels`}</strong>{` as you need:`}</li>
    </ol>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/a7f718291b90bbb53f2381c9e020a473/dc616/OpenHAB_IP_Camera_Binding_12.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "84.34782608695653%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAARCAIAAABSJhvpAAAACXBIWXMAAA7DAAAOwwHHb6hkAAACFElEQVQ4y4WRy2oUQRSGK/GycKGJwSfxebzMwuvCjPoKLtyaGNBNcKFuRNwIhkyIGxMhF0VwEBwMhEliurq6q6tOd1XXdJ06MjMJSWYm+PNDHQr+8506xWh6Ijy6QvUL4f65UJ8KDy6G+mR4OEXTE3T3LN0ZP+kzdIvR9CWqT9K98wxrLNRY6/FVdfsy1Zi/OYY1duDro3yNYW0ca2N4g7GyMVM2nj350F7/+B4XnpZLc25ptnc5Yxuz5UgvznS99JxRICLaEdDiVtqqJHJESKcqUD/RFUPvifznP2FutYLk797uzt5OW2XS2cLkujQmeE94ZN9xvuP6Neu34YAtXpkC8qIwxiilOq7jvUccHCL0dEjuFcstP7da5ZmIIhHHPIo4ADjnhsdG9Ij+INxvEgH+5liASqUEAK21c24YO5r8ZcvPr1V5lsRCZFmWpqkxdmR4iNwp95X/FaHOpBAiTRMppRCxUsp7DEfbHSJ3z+XGyk/1atMXKk1SmR1KayjLcoA/SMZPC7u7uslJyTTiPI7jJBEijlWmjnNOITcWvzbV6+/eQpYpDVoD5ACQ57lzbiCMPR2Eiah68XK72f4hSMkk2o/SVHLOecyNMYj4P/LbN2sbe++aZCGTMgMApZTW2hTGWuu9P5XcDX/b3I7cehtNrrvrHXrnSTKGgMf+2RUrW9X8mjNaAuTWWlMU3T2PanGc/A9we48kqlCnQwAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a7f718291b90bbb53f2381c9e020a473/e4706/OpenHAB_IP_Camera_Binding_12.avif 230w", "/en/static/a7f718291b90bbb53f2381c9e020a473/d1af7/OpenHAB_IP_Camera_Binding_12.avif 460w", "/en/static/a7f718291b90bbb53f2381c9e020a473/7f308/OpenHAB_IP_Camera_Binding_12.avif 920w", "/en/static/a7f718291b90bbb53f2381c9e020a473/18265/OpenHAB_IP_Camera_Binding_12.avif 933w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/a7f718291b90bbb53f2381c9e020a473/a0b58/OpenHAB_IP_Camera_Binding_12.webp 230w", "/en/static/a7f718291b90bbb53f2381c9e020a473/bc10c/OpenHAB_IP_Camera_Binding_12.webp 460w", "/en/static/a7f718291b90bbb53f2381c9e020a473/966d8/OpenHAB_IP_Camera_Binding_12.webp 920w", "/en/static/a7f718291b90bbb53f2381c9e020a473/5431c/OpenHAB_IP_Camera_Binding_12.webp 933w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a7f718291b90bbb53f2381c9e020a473/81c8e/OpenHAB_IP_Camera_Binding_12.png 230w", "/en/static/a7f718291b90bbb53f2381c9e020a473/08a84/OpenHAB_IP_Camera_Binding_12.png 460w", "/en/static/a7f718291b90bbb53f2381c9e020a473/c0255/OpenHAB_IP_Camera_Binding_12.png 920w", "/en/static/a7f718291b90bbb53f2381c9e020a473/dc616/OpenHAB_IP_Camera_Binding_12.png 933w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/a7f718291b90bbb53f2381c9e020a473/c0255/OpenHAB_IP_Camera_Binding_12.png",
              "alt": "OpenHAB IP Camera Binding für INSTAR Kameras",
              "title": "OpenHAB IP Camera Binding für INSTAR Kameras",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ol {...{
      "start": 11
    }}>
      <li parentName="ol">{`All `}<strong parentName="li">{`Items`}</strong>{` will then be displayed on the `}<strong parentName="li">{`Control Panel`}</strong>{` and allow you to control your INSTAR IP camera:`}</li>
    </ol>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/27c8128e12e9054325611d4978124b64/146da/OpenHAB_IP_Camera_Binding_13.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "80.43478260869566%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAQCAIAAACZeshMAAAACXBIWXMAAA7DAAAOwwHHb6hkAAACNklEQVQoz5WP32tSUQDHzyKKwYI9VKSjySyYS1jz6tpDPx57WS8WgdNNJYdaNFb+ut57ft5zvbTSDaL3/pT1A8ZgFCwhooLWBo1kaGHzOlC8pYuCLLc+fB/P53zOAfVq9eva+52P7z6vb5QLmwvj/WgYMNcB6gSd5uqiY4fA+uLixvJyYWWlsJr/9PTJ/IVeNAKYs4s6AB09SMcOEwegwt8HXj97/vbFyw+rr9by+TdLS/dnQ3LYQ6Je3JwPRX046kWRifbhqBcUisWtUulLuVypVrdKpdzDR4ipLHMPcw0xFTIuUwUrGcw1RZtj2pzSGuEapBxUtrf1il6v1QzDqOr6wnwOQcgoRVDGEMXTsenZUCIWj929I6aSsiyJqWQ6lQrM+EVZBKVSsVz+Vq/XDcPQdT2bzUqShDFGCMmyzFXu9XmPHzt6qs8ijDgdgmAftFkHLBNTPq5ysNOi1iq3yxlN83o8R7q7B05YB/vtZ2xDdtuQ2WSa9PszqgqMFo1Go12GEKqcBwKB3p4ei+mkte/05Svj5y9dNJnN7qtuTdPArvYvmSlK9NZNx/DZcy7nqMPlCwbd168JghAIBtXOZYJJJjodTkQQbF6kKAolhDHGOd89sIecDQXC8Ug6lRZFkRBCKSUtUItOMsXkwY2pUGxaTkuSJDH6m71ljNDjSX8SShARGZE0JD+7hOzj2QjnwqHbqRlGGSEU4v8rY5yMx6SEhBhEhP3q7qfcBBNCmNz8Af6DH/J33k8QgCqJ0iMAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/27c8128e12e9054325611d4978124b64/e4706/OpenHAB_IP_Camera_Binding_13.avif 230w", "/en/static/27c8128e12e9054325611d4978124b64/d1af7/OpenHAB_IP_Camera_Binding_13.avif 460w", "/en/static/27c8128e12e9054325611d4978124b64/7f308/OpenHAB_IP_Camera_Binding_13.avif 920w", "/en/static/27c8128e12e9054325611d4978124b64/459ad/OpenHAB_IP_Camera_Binding_13.avif 943w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/27c8128e12e9054325611d4978124b64/a0b58/OpenHAB_IP_Camera_Binding_13.webp 230w", "/en/static/27c8128e12e9054325611d4978124b64/bc10c/OpenHAB_IP_Camera_Binding_13.webp 460w", "/en/static/27c8128e12e9054325611d4978124b64/966d8/OpenHAB_IP_Camera_Binding_13.webp 920w", "/en/static/27c8128e12e9054325611d4978124b64/18615/OpenHAB_IP_Camera_Binding_13.webp 943w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/27c8128e12e9054325611d4978124b64/81c8e/OpenHAB_IP_Camera_Binding_13.png 230w", "/en/static/27c8128e12e9054325611d4978124b64/08a84/OpenHAB_IP_Camera_Binding_13.png 460w", "/en/static/27c8128e12e9054325611d4978124b64/c0255/OpenHAB_IP_Camera_Binding_13.png 920w", "/en/static/27c8128e12e9054325611d4978124b64/146da/OpenHAB_IP_Camera_Binding_13.png 943w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/27c8128e12e9054325611d4978124b64/c0255/OpenHAB_IP_Camera_Binding_13.png",
              "alt": "OpenHAB IP Camera Binding für INSTAR Kameras",
              "title": "OpenHAB IP Camera Binding für INSTAR Kameras",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      